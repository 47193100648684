import { GetSlidesByLangResponse } from './response/GetSlidesByLangResponse'

class SlideServices {
  async getSlidesByLang(page: number, pageSize = 5, lang: string) {
    const config = useRuntimeConfig()

    const data = await $fetch<GetSlidesByLangResponse | null>(`slide/translated/${lang}`, {
      baseURL: config.public.apiBase,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOiI0YWQ3NmU1Ni0wZjA2LTQwMTItYjQ0MC1jODkyNjNhNDkxOTgiLCJkYXRhIjoie1wiSWRcIjpcIjRhZDc2ZTU2LTBmMDYtNDAxMi1iNDQwLWM4OTI2M2E0OTE5OFwiLFwiVXNlcm5hbWVcIjpcIkFkbWluaXN0cmF0b3JcIixcIlJ1bGVzXCI6WzEsMiwzLDRdLFwiVXNlcklkXCI6MyxcIkV4cGlyZUF0XCI6XCIyMDIzLTAzLTA5VDE2OjQ2OjU4LjQ5NzY2NjdaXCJ9IiwibmJmIjoxNjc4Mzc2ODI4LCJleHAiOjE5OTM5OTYwMjgsImlhdCI6MTY3ODM3NjgyOCwiaXNzIjoic2lzLmludGVybm8uY21zLmFwaSIsImF1ZCI6InNpcy5pbnRlcm5vLmNtcy5hcGkifQ.DtLymzid8_oBGg389-5EcEdXPSzY4M5wJ5rUYdovZL8',
      },
      query: {
        pageSize,
        page,
      },
    })

    return data
  }
}

export default new SlideServices()
