<template>
  <section>
    <div v-if="loading">Loading</div>
    <div>
      <Swiper
        class="swiper"
        :modules="[SwiperAutoplay, SwiperEffectFade, SwiperPagination]"
        :slides-per-view="1"
        :effect="'fade'"
        :pagination="{
          clickable: true,
          renderBullet(index, className) {
            return `<span class='${className}'><div class='slider-line'></div><div class='slider-text'>${slides[index].shortenedTitle}</div></span>`
          },
          modifierClass: 'home-swiper-pagination',
        }"
        :autoplay="{
          delay: 10000,
          disableOnInteraction: false,
        }"
        @slide-change="changeSwiperIndex"
      >
        <SwiperSlide
          v-for="(slide, idx) in slides"
          :key="idx"
          class="swiper-slide"
          :style="`background-color: ${slide.bg}; color: ${slide.color}`"
        >
          <NuxtImg
            v-if="slide.imageUrl"
            class="swiper-slide__img"
            :src="slide.imageUrl"
            :alt="slide.title"
            :preload="idx == 0 ? true : false"
            quality="100"
            :fetchpriority="idx == 0 ? 'high ' : 'low'"
            :loading="idx == 0 ? 'eager ' : 'lazy'"
            sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:1920px"
          />
          <div v-if="slide.videoUrl" class="swiper-overlay"></div>
          <video
            v-if="slide.videoUrl"
            :id="'video-carousel' + idx"
            :src="slide.videoUrl"
            playsinline
            autoplay
            muted
            loop
            preload="none"
          ></video>
          <div class="swiper-slide__content container">
            <div class="swiper-slide__content__section">
              <h3 class="swiper-slide__content__section__title font-bold">
                {{ slide.title }}
              </h3>
              <p class="swiper-slide__content__section__text">
                {{ slide.description }}
              </p>
              <a :href="slide.slideButton.redirectUrl">
                <button
                  :style="{
                    backgroundColor: slide.slideButton.backgroundColor,
                    color: slide.slideButton.color,
                  }"
                  class="swiper-slide__content__section__button font-bold"
                >
                  {{ slide.slideButton.title }}
                </button>
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script setup lang="ts">
defineProps<{
  slides: Array<any>
  loading?: boolean
}>()

const swiperActiveIndex = ref(0)

const changeSwiperIndex = (val: any) => {
  swiperActiveIndex.value = val.activeIndex
}
</script>

<style lang="scss">
.home-swiper-paginationbullets {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  width: 100% !important;
  margin-bottom: 60px !important;
  bottom: 0px;

  @media screen and (max-width: $mobile) {
    padding: 0px 20px;
  }

  .swiper-pagination-bullet {
    width: 169px;
    background: none;
    border-radius: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    bottom: 0px;
    height: auto;
    transition: background 200ms;
  }

  .swiper-pagination-bullet-active .slider-line::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 200ms;
  }

  .swiper-pagination-bullet-active .slider-line {
    background: rgba(238, 238, 238, 0.2);
  }

  .swiper-pagination-bullet-active .slider-line::before {
    background-color: #fb9335;
    animation: slide-progress 11s cubic-bezier(0.3, 0, 0.3, 1) forwards;
  }

  .swiper-pagination-bullet-active::before > .swiper-paused {
    opacity: 0;
  }

  .slider-text {
    /* position: fixed; */
    color: white;
    bottom: 0px;
    margin-top: 10px;
    margin-left: 0px;
    text-align: center;
    font: normal normal bold 16px/24px Montserrat;
  }

  .slider-line {
    width: 100%;
    height: 2px;
    background: rgba(238, 238, 238, 0.1);
    border-radius: 0;
    overflow: hidden;
    transition: background 200ms;
    position: absolute;
  }

  @keyframes slide-progress {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }
}
</style>

<style lang="scss" scoped>
.swiper {
  /* max-width: 600px; */
  width: 100%;
  height: 100vh;
  background-color: rgb(33, 35, 73);
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  video {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-overlay {
    z-index: 999 !important;
    background: 0% 0% no-repeat padding-box padding-box rgba(31, 33, 74, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.swiper-slide__img {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide__content {
  z-index: 1;
  display: flex;
  width: 100%;
  height: auto;
  z-index: 999;
  margin-top: 50px;
}

.swiper-slide__content__section {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 100px;
}

.swiper-slide__content__section__title {
  font: normal normal 900 40px/49px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.swiper-slide__content__section__text {
  font: normal normal normal 16px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

.swiper-slide__content__section__button {
  background: #fb9335 0% 0% no-repeat padding-box;
  border-radius: 41px;
  opacity: 1;
  text-align: center;
  font: normal normal 900 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  border: none;
  width: 352px;
  height: 56px;
  padding: 3px 8px;
}

@media screen and (max-width: $mobile) {
  .swiper {
    height: calc(100vh - 60px);
  }

  .swiper-slide__content {
    padding: 100px 15px;
    margin-top: 0px;
  }

  .swiper-pagination {
    padding: 0px 14px;
    margin-bottom: 50px;
    bottom: 0px;
  }

  .swiper-slide__content__section {
    width: 85%;
    padding: 0 !important;
  }

  .swiper-slide__content__section__title {
    font: normal normal 900 20px/34px Montserrat;
  }

  .swiper-slide__content__section__button {
    width: 216px;
    height: 36px;
    font: normal normal 900 13px/16px Montserrat;
  }
}
</style>
