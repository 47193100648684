<template>
  <div class="perfomance-commitment">
    <div class="perfomance-commitment__text">
      <div class="image">
        <VImage
          alt="mulher com tablet"
          src="images/home/performance-and-commitment.webp"
          position="right"
          class="perfomance-commitment__main__content__image"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 order-2 order-md-1 col-md-6 d-flex">
            <div class="perfomance-commitment__main__content">
              <h3 class="perfomance-commitment__main__content__title font-bold">
                {{ t('message.home.performance.title') }}
              </h3>
              <div>
                <p class="perfomance-commitment__main__content__text">
                  {{ $t('message.home.performance.descriptionFirst') }}
                </p>
                <p class="perfomance-commitment__main__content__text">
                  {{ $t('message.home.performance.descriptionSecond') }}
                </p>
              </div>
              <NuxtLink :to="localePath('quem-somos')" class="perfomance-commitment__main__content__link font-bold">
                {{ $t('message.home.performance.button') }}
                <span v-html="arrow"></span>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="perfomance-commitment__cards container">
      <div class="perfomance-commitment__cards__bg"></div>
      <div class="perfomance-commitment__cards__title">
        <h1 v-html="$t('message.home.performance.cards.title')"></h1>
      </div>
      <div class="perfomance-commitment__cards__content">
        <div v-for="(card, index) in cards" :key="index" class="perfomance-commitment__cards__card">
          <NuxtImg
            class="perfomance-commitment__cards__card__image"
            :src="`/images/home/${card.image}`"
            sizes="xs:100vw md:40vw lg:458px xl:536px"
            loading="lazy"
            alt="card image"
            quality="100"
          />
          <h5 class="perfomance-commitment__cards__card__title font-bold">
            {{ $t(card.title) }}
          </h5>
          <VButton
            color="secondary"
            class="perfomance-commitment__cards__card__button"
            :to="{
              path: localePath(card.link),
              query: { scrollTo: card.scrollTo },
            }"
          >
            {{ $t(card.buttonText) }}
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { t } = useI18n()
const arrow =
  ref<string>(`<svg style="align-self: center;" xmlns="http://www.w3.org/2000/svg" width="14.002" height="13.647" viewBox="0 0 14.002 13.647">
            <path id="Caminho_816" data-name="Caminho 816"
            d="M5.953-11.909a.745.745,0,0,0,.013,1.072L9.731-7.25H.75A.748.748,0,0,0,0-6.5v1a.748.748,0,0,0,.75.75H9.731L5.966-1.163A.751.751,0,0,0,5.953-.091L6.647.6A.747.747,0,0,0,7.706.6l6.075-6.075a.747.747,0,0,0,0-1.059L7.706-12.6a.747.747,0,0,0-1.059,0Z"
            transform="translate(0 12.823)" fill="#FB9335" />
            </svg>`)

const cards = ref([
  {
    title: 'message.home.performance.cards.first.title',
    link: 'o-que-fazemos',
    scrollTo: 'transformation',
    image: 'transformation.webp',
    buttonText: 'message.home.performance.cards.first.button',
  },
  {
    title: 'message.home.performance.cards.second.title',
    link: 'o-que-fazemos',
    scrollTo: 'services',
    image: 'services.webp',
    buttonText: 'message.home.performance.cards.second.button',
  },
])
</script>

<style lang="scss" scoped>
.perfomance-commitment {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    background: #960b38 0% 0% no-repeat padding-box;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__main {
    display: flex;
    align-items: center;

    &__content {
      align-self: center;
      justify-content: center;
      justify-items: center;
      display: flex;
      height: 500px;
      flex-direction: column;
      width: 100%;

      &__title {
        font: normal normal 900 40px/49px Montserrat;
        letter-spacing: 0px;
        color: #fb9335;
        margin-bottom: 30px;
      }

      &__text {
        text-align: left;
        font: normal normal normal 16px/24px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        margin-top: 15px;
      }

      &__link {
        margin-top: 30px;
        display: flex;
        gap: 15px;
        text-decoration: none;
        font: normal normal 900 16px/24px Montserrat;
        color: #fb9335;
        align-content: center;

        &:hover {
          text-decoration: none;
          color: #fb9335;
        }

        &:focus {
          text-decoration: none;
          color: #fb9335;
        }

        span {
          margin-bottom: -7px !important;
        }
      }

      &__image {
        height: 500px;
      }
    }
  }

  &__cards {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    color: #fff;
    width: 100%;
    padding-bottom: 50px;

    &__bg {
      background: #960b38 0% 0% no-repeat padding-box;
      position: absolute;
      z-index: -1;
      height: 290px;
      width: 100%;
    }
    &__content {
      display: flex;
      gap: 20px;
      width: 100%;
      align-items: center;
      justify-content: center;
      perspective: 1000px;
    }

    &__title {
      justify-content: center;
      display: flex;
      font: normal normal 50 40px/49px Montserrat-thin;
    }

    &__card {
      width: clamp(500px, 100%, 536px);
      height: 407px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      box-sizing: border-box;
      position: relative;

      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform;
      transition: transform 0.5s ease-out;

      &:hover {
        transform: translateY(-10px);
      }

      &__title {
        text-align: center;
        font: normal normal 900 35px/43px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
      }

      &__button {
        text-align: center;
        font: normal normal 900 16px/19px Montserrat;
        letter-spacing: 0px;
        max-width: 352px;
        width: 90%;
        height: 56px;
        border-radius: 41px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
      }

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
        @media screen and (max-width: $mobile) {
          object-position: 10%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .perfomance-commitment {
    margin: 0px;
    padding: 0px;

    &__text {
      padding-top: 50px;
      padding-bottom: 20px;
    }

    &__main {
      padding-top: 40px;
      padding-left: 14px;

      &__content {
        height: auto;

        &__title {
          margin-top: 30px;
          margin-bottom: 10px;
          width: 60%;
          font: normal normal 900 20px/24px Montserrat;
        }

        &__text {
          font: normal normal normal 16px/24px Montserrat;
          margin-left: 30px;
        }

        &__image {
          height: 255px !important;
          width: 100% !important;
          position: relative !important;
          left: 0 !important;
          padding: 0px !important;
        }

        &__link {
          margin-top: 30px;
        }
      }
    }

    &__cards {
      width: 100%;
      gap: 8px;

      &__content {
        gap: 10px;
      }

      &__bg {
        height: 160px;
      }

      &__title {
        font-size: 25px;
      }

      &__card {
        width: 168px;
        height: 200px;
        gap: 10px;

        &__title {
          font: normal normal 900 16px/24px Montserrat;
        }

        &__button {
          font: normal normal 900 14px/18px Montserrat;
          width: 120px;
          height: 35px;
          padding: 0px !important;
        }
      }
    }
  }
}
</style>
