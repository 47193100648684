<template>
  <main>
    <HomeSlides v-if="slides" :slides="slides" />

    <HomePerformanceAndCommitment />

    <SharedContentImageSide
      :content-image-side="{
        image: '/images/home/people-and-technology.webp',
        title: {
          content: 'message.home.people.title',
          color: '#960b38',
        },
        content: {
          text: 'message.home.people.description',
          style: ''
        },
      }"
      side="left"
    />

    <SharedBannerWithContent
      v-if="locale == 'pt'"
      :content-image="{
        image: '/images/home/part-of-team.webp',
        title: $t('message.home.partOfTeam.title'),
        content: $t('message.home.partOfTeam.description'),
        button: {
          color: '#960B38',
          background: '#FFB500',
          content: 'message.home.partOfTeam.buttonText',
          link: 'https://www.linkedin.com/jobs/search/?f_C=1324820&geoId=92000000&sortBy=DD',
        },
      }"
    />

    <HomeArticles v-if="locale == 'pt'" />

    <SharedBannerWithContent
      :content-image="{
        image: '/images/home/talk-to-sis.webp',
        title: $t('message.home.talkToSis.title'),
        content: $t('message.home.talkToSis.description'),
        button: {
          color: '#960B38',
          background: '#FFB500',
          content: 'message.home.talkToSis.buttonText',
          link: localePath('contato'),
        },
      }"
    />
  </main>
</template>

<script setup>
import ArticleService from '~/services/articles/ArticleService'
import SlideService from '~/services/slides/SlideService'

const blogStore = useBlogStore()
const { locale } = useI18n()
const { t } = useI18n()

const { data: slidesFetch } = await useAsyncData(
  'slides',
  async () => await SlideService.getSlidesByLang(0, 5, locale.value == 'es' ? 'esp' : locale.value),
)

const { data: articlesFetch } = await useAsyncData(
  'articlesHome',
  async () => await ArticleService.getListFilterAdvanced(0, 5),
)

if (articlesFetch.value) blogStore.setArticles(articlesFetch.value.data)
const slides = computed(() => slidesFetch.value)
</script>
